import {
  colors,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  fonts as fontTokens,
  fontSizes,
  transition,
  mediaQueries,
  space as spaceTokens,
  sizes,
} from "./tokens"
import { rhythm } from "./typography"

const fonts = {}
for (const fontFamily in fontTokens) {
  if (fontTokens.hasOwnProperty(fontFamily)) {
    fonts[fontFamily] = fontTokens[fontFamily].join(`,`)
  }
}

const space = spaceTokens.map(token => rhythm(token))

export {
  colors,
  mediaQueries,
  fonts,
  fontSizes,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  space,
  sizes,
  transition,
}
