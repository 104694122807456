import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FaGithub, FaTwitter } from "react-icons/fa"

import Container from "../../components/container"
import FooterNavigation from "./navigation"
import LazySVGImg from "../lazy-svg"

import {
  mediaQueries,
  transition,
  colors,
  radii,
  space,
} from "../../utils/presets"

const SocialItem = ({ href, title, children, isInverted }) => (
  <a
    href={href}
    title={title}
    css={{
      alignItems: `center`,
      borderRadius: radii[6],
      boxSizing: `border-box`,
      color: colors.lilac,
      display: `flex`,
      flex: `0 0 ${space[7]}`,
      flexDirection: `row`,
      height: space[7],
      justifyContent: `center`,
      marginLeft: space[1],
      position: `relative`,
      transition: `all ${transition.speed.default} ${transition.curve.default}`,
      width: space[7],
      "&:hover": {
        background: isInverted ? colors.gatsby : colors.white,
        color: isInverted ? colors.white : colors.gatsby,
      },
    }}
  >
    {children}
  </a>
)

SocialItem.propTypes = {
  href: PropTypes.string.isRequired,
  isInverted: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

const Footer = ({ navItems, background, isInverted }) => (
  <div
    className="footer-container"
    css={{
      background: background ? background : false,
      paddingTop: space[12],
      WebkitFontSmoothing: isInverted ? `antialiased` : false,
      [mediaQueries.phablet]: {
        paddingTop: space[9],
      },
      [mediaQueries.tablet]: {
        paddingTop: space[13],
      },
    }}
  >
    <Container>
      <div
        css={{
          alignItems: `center`,
          display: `flex`,
        }}
      >
        <Link
          to="/"
          css={{
            color: `inherit`,
            marginRight: space[3],
            textDecoration: `none`,
          }}
          aria-label="Link to home"
        >
          <LazySVGImg
            src="monogram.svg"
            css={{
              display: `block`,
              height: 28,
              margin: 0,
              width: `auto`,
            }}
            alt=""
          />
        </Link>
        <div
          css={{
            display: `flex`,
            marginLeft: `auto`,
          }}
        >
          <SocialItem
            href="https://twitter.com/gatsbyjs"
            title="Follow us on Twitter @gatsbyjs"
            isInverted={isInverted}
          >
            <FaTwitter style={{ verticalAlign: `text-top` }} />
          </SocialItem>
          <SocialItem
            href="https://github.com/gatsbyjs/gatsby"
            title="GitHub"
            isInverted={isInverted}
          >
            <FaGithub style={{ verticalAlign: `text-top` }} />
          </SocialItem>
        </div>
      </div>
    </Container>
    <FooterNavigation navItems={navItems} isInverted={isInverted} />
  </div>
)

Footer.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      parentPage: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
      }),
    })
  ).isRequired,
  isInverted: PropTypes.bool,
  background: PropTypes.string,
}

export default Footer
