import React from "react"
import PropTypes from "prop-types"

import { Button } from "../../components/core/button"

import { space } from "../../utils/presets"
import { getLink } from "../../utils/navigation"

const FeaturesCTA = ({ linkText, associatedPage }) => {
  if (!associatedPage) {
    return null
  }

  return (
    <p
      css={{
        marginTop: space[9],
        paddingBottom: space[6],
        position: `relative`,
        textAlign: `center`,
        zIndex: 1,
      }}
    >
      <Button
        to={getLink(associatedPage)}
        buttonStyle="primary"
        ariaLabel={`Find out about: ${associatedPage.name}`}
      >
        {linkText}
      </Button>
    </p>
  )
}

FeaturesCTA.propTypes = {
  associatedPage: PropTypes.object,
  linkText: PropTypes.string,
}

export default FeaturesCTA
