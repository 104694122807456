import React from "react"
import PropTypes from "prop-types"

import { mediaQueries, transition, space, colors } from "../../utils/presets"
import { isMobileSafari } from "../../utils/is-mobile-safari"

const hamburgerLayerHeight = 3

class Hamburger extends React.Component {
  constructor(props) {
    super(props)

    this.handleOnClick = this._handleOnClick.bind(this)

    this.state = { isMobileNavOpen: false }
  }

  _handleOnClick() {
    this.setState({ isMobileNavOpen: !this.state.isMobileNavOpen })
    this.props.updateParentState({
      isMobileNavOpen: !this.state.isMobileNavOpen,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      isMobileNavOpen: nextProps.isMobileNavOpen,
    })
  }

  render() {
    const defaultColor = this.props.isInverted ? colors.white : colors.black
    const activeColor = colors.white
    const borderRadius = 2

    const shared = {
      borderRadius: borderRadius,
      height: hamburgerLayerHeight,
      background: defaultColor,
      content: `" "`,
      position: `absolute`,
      transition: `all ${transition.speed.default} ${transition.curve.default}`,
    }

    const sharedActive = {
      background: activeColor,
      top: 0,
      width: 24,
      transform: `rotate(45deg)`,
    }

    const hamburgerStyles = {
      width: 24,
      height: hamburgerLayerHeight,
      margin: `10px 0`,
      background: defaultColor,
      position: `relative`,
      zIndex: 1000,
      borderRadius: borderRadius,
      transition: `all ${
        transition.speed.default
      } cubic-bezier(.68,-.55,.265,1.55)`,
      ":before": {
        content: JSON.stringify(``),
        top: -7,
        width: 20,
        right: 0,
        ...shared,
      },
      ":after": {
        content: JSON.stringify(``),
        top: 7,
        width: 16,
        right: 0,
        ...shared,
      },
    }

    return (
      <button
        aria-controls="gatsby-nav"
        aria-expanded={this.state.isMobileNavOpen && `active`}
        aria-label="Toggle Navigation"
        className={this.state.isMobileNavOpen && `active`}
        onClick={this.handleOnClick}
        css={{
          background: `none`,
          border: 0,
          display: `block`,
          marginLeft: `auto`,
          marginTop: -6,
          padding: 0,
          position: `relative`,
          zIndex: 22,
          cursor: `pointer`,
          transition: `all ${transition.speed.default} ${
            transition.curve.default
          }`,
          [this.props.bpToggleNavigation]: {
            display: `none`,
          },
          "&:hover": isMobileSafari()
            ? {}
            : {
                "& .hamburger": {
                  "&:before": { width: 24, top: -9 },
                  "&:after": { width: 24, top: 9 },
                },
              },
          "&.active": {
            position: `fixed`,
            right: space[7],
            "& .hamburger": {
              background: `0 0`,
              "&:after": {
                ...sharedActive,
                transform: `rotate(-45deg)`,
              },
              "&:before": { ...sharedActive },
              "&:hover": {
                transform: `scale(1.2)`,
              },
            },
            [mediaQueries.phablet]: {
              right: `calc((${space[7]} + 5vw))`,
            },
          },
        }}
      >
        <div className="hamburger" css={hamburgerStyles} />
      </button>
    )
  }
}

Hamburger.propTypes = {
  isMobileNavOpen: PropTypes.bool.isRequired,
  updateParentState: PropTypes.func.isRequired,
  isInverted: PropTypes.bool,
  bpToggleNavigation: PropTypes.string,
}

export default Hamburger
