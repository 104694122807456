// @todo Change URLs when shipping to gatsbyjs.com.

export default {
  description: `Modern development, for the content web`,
  title: `Gatsby Inc.`,
  keywords: `gatsby, javascript, react, graphql, fast`,
  url: `https://www.gatsbyjs.com/`,
  logo: `https://www.gatsbyjs.com/android-chrome-512x512.png`,
  image: `https://www.gatsbyjs.com/gatsby.jpg`,
  twitter: `@gatsbyjs`,
  fbAppID: ``,
}
