import React from "react"

import Default from "./default"
import Card from "../core/card"
import CTA from "./cta"
import HowItWorksCard from "../../templates/how-it-works/section"
import Diagram from "../diagram"

const Section = ({ section, parentSectionLayout, index }) => {
  if (section.title === `Home Diagram`) {
    return <Diagram />
  } else if (section.layout === `fullWidthCentered`) {
    return (
      <CTA
        associatedPage={section.associatedPage}
        title={section.title}
        text={section.text}
        linkText={section.linkText}
      />
    )
  } else if (parentSectionLayout === `cards` && section.associatedPage) {
    return <Card section={section} index={index} />
  } else if (parentSectionLayout === `howItWorks`) {
    return <HowItWorksCard section={section} index={index} />
  } else {
    return <Default section={section} />
  }
}

export default Section
