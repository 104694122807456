import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import SVGInline from "react-svg-inline"
import { injectGlobal } from "react-emotion"
import deepmerge from "deepmerge"

import ReadMore from "../../components/read-more-button"
import { getLink } from "../../utils/navigation"
import {
  colors,
  fontSizes,
  mediaQueries,
  lineHeights,
  transition,
  fonts,
  shadows,
  space,
} from "../../utils/presets"
import {
  howItWorksSVGStyles,
  withHowItWorksGridBorder,
  primaryButtonHoverStyle,
  bodyCopy,
  howItWorksSVGHoverStyles,
} from "../../utils/styles"
import LinkWithArrow from "../../components/link-with-arrow"

const cardPadding = space[7]

// couldn't make the counter prefix work inline, so let's go global
injectGlobal`
.how-it-works-card-title::after {
  content: "Fig. " counter(how-it-works-counter);
  counter-increment: how-it-works-counter;
}
`

const figureStyles = {
  color: colors.howItWorks.base,
  position: `absolute`,
  top: 0,
  left: 0,
  fontWeight: 400,
  fontFamily: fonts.header,
  textTransform: `uppercase`,
  fontSize: fontSizes[0],
  opacity: 0.3,
}

const styles = {
  defaults: {
    color: `${colors.howItWorks.dark} !important`,
    padding: cardPadding,
    flex: `1 1 100%`,
    ...withHowItWorksGridBorder,
    marginTop: -1,
    // reset withHowItWorksGridBorder for two columns
    [mediaQueries.desktop]: {
      "&:after": {
        display: `none !important`,
      },
    },
  },
  cardHoverDesktopStyle: {
    "&:before": {
      background: colors.white,
      content: `" "`,
      display: `block`,
      height: `100%`,
      left: 0,
      position: `absolute`,
      top: 0,
      bottom: 0,
      transform: `scaleY(0)`,
      transition: `transform ${transition.speed.default} ${
        transition.curve.default
      }`,
      transformOrigin: `center bottom`,
      width: `100%`,
      zIndex: -1,
    },
    "&:hover:before": {
      transform: `scaleY(1)`,
    },
    "&:hover h2": {
      color: `${colors.gatsby} !important`,
    },
    "&:hover div": {
      color: `${colors.night} !important`,
    },
    "&:hover .read-more": {
      ...primaryButtonHoverStyle,
      color: `${colors.white} !important`,
      border: `1px solid ${colors.gatsby} !important`,
    },
    "&:hover": {
      boxShadow: shadows.dialog,
    },
  },
}

const Card = ({ section, index }) => {
  const { title, keyvisual, associatedPage, text } = section
  const Visual = keyvisual
    ? require(`!raw-loader!svgo-loader?{"plugins":[{"removeViewBox":false}]}!../../assets/${
        keyvisual.name
      }.svg`)
    : false
  const isHowItWorksListPage =
    associatedPage &&
    associatedPage.parentPage &&
    associatedPage.parentPage.slug === `how-it-works`
  const isOdd = (index + 1) % 2 === 0

  return associatedPage ? (
    <Link
      to={getLink(associatedPage)}
      css={deepmerge(
        {
          ...styles.defaults,
          ...styles.cardHoverDesktopStyle,
          marginLeft: `-${cardPadding}`,
          marginRight: `-${cardPadding}`,
          display: `flex`,
          flexDirection: `column`,
          position: `relative`,
          justifyContent: `flex-start`,
          textDecoration: `none`,
          transitionDuration: transition.speed.default,
          transitionProperty: `color, background-color, box-shadow, transform`,
          zIndex: `499`,
          "&:hover svg": {
            opacity: 1,
            ...howItWorksSVGHoverStyles,
          },
        },
        {
          [mediaQueries.desktop]: {
            marginLeft: isOdd ? 0 : `-${cardPadding}`,
            marginRight: !isOdd ? 0 : `-${cardPadding}`,
          },
        }
      )}
    >
      <div
        css={{
          position: `relative`,
          display: `flex`,
          width: `100%`,
        }}
      >
        <h2
          className="how-it-works-card-title"
          css={{
            paddingTop: `${parseInt(cardPadding, 10) * 1}rem`,
            flex: `1 1 auto`,
            position: `relative`,
            [mediaQueries.phablet]: {
              fontSize: fontSizes[6],
            },
            [mediaQueries.desktop]: {
              fontSize: fontSizes[5],
            },
            "&:after": {
              ...figureStyles,
            },
          }}
        >
          <LinkWithArrow title={title} />
        </h2>
        {Visual && (
          <div
            css={{
              flexShrink: 0,
              width: 120,
              height: 120,
              padding: isHowItWorksListPage ? 0 : 20,
              justifyContent: `center`,
              "& svg": {
                width: `100%`,
                height: `100%`,
                position: `relative`,
                zIndex: 2,
                opacity: 0.5,
                ...howItWorksSVGStyles,
              },
              [mediaQueries.desktop]: {
                width: 140,
                height: 140,
                marginRight: isHowItWorksListPage ? -20 : 0,
                padding: isHowItWorksListPage ? 0 : 40,
              },
              [mediaQueries.hd]: {
                width: 160,
                height: 160,
              },
              [mediaQueries.hhd]: {
                width: 180,
                height: 180,
              },
            }}
          >
            <SVGInline svg={Visual} />
          </div>
        )}
      </div>
      <div
        css={{
          [mediaQueries.desktop]: { fontSize: fontSizes[1] },
          color: colors.gray.neutral,
        }}
      >
        <div
          css={{ maxWidth: 400 }}
          dangerouslySetInnerHTML={{
            __html: text && text.childMarkdownRemark.html,
          }}
        />
      </div>
      <div css={{ marginTop: `auto` }}>
        <ReadMore
          customStyles={{
            background: `rgba(255,255,255,0.8)`,
            border: `1px solid ${colors.howItWorks.bright}`,
            color: colors.howItWorks.base,
          }}
        />
      </div>
    </Link>
  ) : (
    <div
      css={{
        ...styles.defaults,
        marginLeft: isOdd ? 0 : `-${cardPadding}`,
        marginRight: !isOdd ? 0 : `-${cardPadding}`,
      }}
    >
      <p css={{ ...figureStyles, left: cardPadding, top: cardPadding }}>
        Orientation
      </p>
      <div
        css={{
          ...bodyCopy,
          color: colors.gray.neutral,
          position: `relative`,
          paddingTop: `${parseInt(cardPadding, 10) * 1}rem`,
          [mediaQueries.phablet]: {
            lineHeight: lineHeights.loose,
          },
        }}
        dangerouslySetInnerHTML={{
          __html: text && text.childMarkdownRemark.html,
        }}
      />
    </div>
  )
}

Card.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number,
}

export default Card
