import Typography from "typography"
import CodePlugin from "typography-plugin-code"
import { fonts, colors, lineHeights, mediaQueries } from "./tokens"

const settings = {
  headerFontFamily: fonts.header,
  bodyFontFamily: fonts.system,
  monospaceFontFamily: fonts.monospace,
  headerColor: colors.night,
  bodyColor: colors.gray.copy,
  scaleRatio: 2,
  baseLineHeight: lineHeights.default,
  headerLineHeight: lineHeights.dense,
  plugins: [new CodePlugin()],
  overrideStyles: ({ rhythm }, options) => {
    return {
      html: {
        overflowY: `auto`,
      },
      body: {
        overflow: `hidden`,
      },
      blockquote: {
        paddingLeft: rhythm(options.blockMarginBottom),
        marginLeft: 0,
        borderLeft: `0.25rem solid ${colors.ui.light}`,
      },
      hr: {
        backgroundColor: colors.ui.light,
      },
      a: {
        color: colors.gatsby,
      },
      "tt, code": {
        fontFamily: options.monospaceFontFamily.join(`,`),
        fontSize: `92.5%`,
        WebkitFontFeatureSettings: `"clig" 0, "calt" 0`,
        fontFeatureSettings: `"clig" 0, "calt" 0`,
        paddingTop: `0.1em`,
        paddingBottom: `0.1em`,
        background: colors.accentLight,
      },
      "h1, h2, h3, h4, h5, h6": {
        color: colors.black,
      },
      "h1 tt, h1 code, h2 tt, h2 code, h3 tt, h3 code, h4 tt, h4 code, h5 tt, h5 code, h6 tt, h6 code, strong tt, strong code": {
        fontWeight: `normal`,
      },
      "svg text": {
        fontFamily: `${options.bodyFontFamily.join(`,`)} !important`,
      },
      [mediaQueries.hd]: {
        html: { fontSize: `${(18 / 16) * 100}%` },
      },
    }
  },
}

const typography = new Typography(settings)

export const { rhythm } = typography
export const { options } = typography
export const { scale } = typography

export default typography
