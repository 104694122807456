import { graphql } from "gatsby"
import React, { Fragment } from "react"
import PropTypes from "prop-types"
import range from "range"

import Section from "../section"
import Container from "../container"
import { mediaQueries, space } from "../../utils/presets"
import { withHowItWorksGridBorder } from "../../utils/styles"

const breakpoint = mediaQueries.desktop

const OuterContainer = ({ children, isHowItWorks }) => (
  <div
    css={{
      maxWidth: isHowItWorks ? false : `1000px`,
      margin: `auto`,
      [breakpoint]: {
        ...(isHowItWorks && {
          display: `flex`,
          flexDirection: `row`,
          ...withHowItWorksGridBorder,
        }),
      },
    }}
  >
    {children}
  </div>
)

OuterContainer.propTypes = {
  isHowItWorks: PropTypes.bool,
}

const LayoutContainer = ({ children, isHowItWorks }) =>
  isHowItWorks ? (
    <Container>{children}</Container>
  ) : (
    <Fragment>{children}</Fragment>
  )

LayoutContainer.propTypes = {
  isHowItWorks: PropTypes.bool,
}

const FeaturesSection = ({ section }) => {
  const gutter = space[4]
  const isHowItWorks = section.layout === `howItWorks`
  const parentSectionLayout = section.layout

  const InnerSection = ({ section, index }) => (
    <div
      css={{
        padding: isHowItWorks ? false : `0 ${gutter}`,
        [breakpoint]: {
          padding: isHowItWorks ? false : `0 ${gutter}`,
          display: `flex`,
          width: isHowItWorks ? `50%` : false,
        },
      }}
    >
      <Section
        section={section}
        parentSectionLayout={parentSectionLayout}
        index={index}
      />
    </div>
  )

  InnerSection.propTypes = {
    index: PropTypes.number,
  }

  return (
    <LayoutContainer isHowItWorks={isHowItWorks}>
      <div
        css={{
          margin: isHowItWorks ? false : `0 -${gutter}`,
        }}
      >
        {range.range(0, section.featuresList.length / 2).map(i => (
          <OuterContainer key={i} isHowItWorks={isHowItWorks}>
            <InnerSection section={section.featuresList[2 * i]} index={2 * i} />
            {section.featuresList.length > 2 * i + 1 ? (
              <InnerSection
                section={section.featuresList[2 * i + 1]}
                index={2 * i + 1}
              />
            ) : null}
          </OuterContainer>
        ))}
      </div>
    </LayoutContainer>
  )
}

FeaturesSection.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number,
}

export default FeaturesSection

export const FeaturesListFragment = graphql`
  fragment FeaturesListFragment on ContentfulSection {
    featuresList {
      title
      text {
        childMarkdownRemark {
          html
        }
      }
      keyvisual {
        name
      }
      associatedPage {
        name
        slug
        parentPage {
          name
          slug
        }
      }
    }
  }
`
