import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import SVGInline from "react-svg-inline"
import { injectGlobal } from "react-emotion"

import logo from "!raw-loader!svgo-loader!../../assets/logo.svg"
import { space, colors } from "../../utils/presets"

injectGlobal`
  .nav-open .logo-holder {
    position: fixed !important;
    z-index: 100 !important;
  }

  .nav-open .logo {
    fill: ${colors.white} !important;
  }

  .nav-open .logo .st0 {
    fill: transparent !important;
  }

  .nav-open .logo .st1 {
    fill: ${colors.white} !important;
  }

  .nav-open svg > path {
    display: none
  }
`

const Logo = ({ isInverted }) => (
  <Link
    to="/"
    css={{
      alignItems: `center`,
      color: `inherit`,
      display: `flex`,
      marginRight: space[3],
      textDecoration: `none`,
      "& .logo": {
        fill: isInverted ? colors.white : colors.black,
      },
      "& .st0": {
        fill: isInverted ? `transparent` : colors.white,
      },
      "& .st1": {
        fill: isInverted ? colors.white : colors.gatsby,
      },
    }}
    className="logo-holder"
    aria-label="Link to home"
  >
    <SVGInline svg={logo} height={space[6]} className="logo" />
  </Link>
)

Logo.propTypes = {
  isInverted: PropTypes.bool,
}

export default Logo
