import React from "react"
import PropTypes from "prop-types"
import deepmerge from "deepmerge"

import { container } from "../utils/styles"

const Container = ({ children, customStyles, innerCustomStyles }) => (
  <div css={deepmerge(container, customStyles ? customStyles : {})}>
    <div css={{ width: `100%`, ...innerCustomStyles }}>{children}</div>
  </div>
)

Container.propTypes = {
  customStyles: PropTypes.object,
  innerCustomStyles: PropTypes.object,
}

export default Container
