import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { getLink } from "../../utils/navigation"
import LazySVGImg from "../lazy-svg"

const DefaultSection = ({ section }) => {
  const Keyvisual = section.keyvisual && section.keyvisual.name

  return (
    <div>
      {Keyvisual && (
        <LazySVGImg src={`${Keyvisual}.svg`} alt={``} css={{ maxWidth: 160 }} />
      )}
      <h3
        css={{
          maxWidth: 440,
        }}
      >
        {section.associatedPage && (
          <Link to={getLink(section.associatedPage)}>{section.title}</Link>
        )}
        {!section.associatedPage && section.title}
      </h3>
      <div
        dangerouslySetInnerHTML={{
          __html: section.text && section.text.childMarkdownRemark.html,
        }}
      />
    </div>
  )
}

DefaultSection.propTypes = {
  section: PropTypes.object.isRequired,
}

export default DefaultSection
