import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Subtitle from "../../components/hero/subtitle"
import LinkWithArrow from "../../components/link-with-arrow"
import { getLink } from "../../utils/navigation"
import {
  colors,
  lineHeights,
  radii,
  transition,
  mediaQueries,
  space,
  shadows,
} from "../../utils/presets"
import LazySVGImg from "../lazy-svg"
import ReadMore from "../../components/read-more-button"

const Card = ({ section, index }) => {
  const { title, keyvisual, associatedPage, text } = section
  const isOdd = (index + 1) % 2 === 0
  return (
    <Link
      to={getLink(associatedPage)}
      css={{
        color: colors.gray.copy,
        display: `flex`,
        flex: `1 1 100%`,
        flexDirection: `column`,
        marginBottom: space[9],
        position: `relative`,
        padding: space[7],
        textDecoration: `none`,
        transitionDuration: transition.speed.default,
        transitionProperty: `color,background-color,box-shadow,transform`,
        transitionTimingFunction: transition.curve.fastOutLinearIn,
        zIndex: `499`,
        "&:hover": {
          backgroundColor: `rgba(255,255,255,0.9)`,
          boxShadow: shadows.dialog,
          transform: `translateY(-2px)`,
          "& div": {
            color: colors.gatsby,
          },
          "& h2": {
            color: colors.gatsby,
          },
          "& .read-more": {
            color: colors.white,
          },
        },
        [mediaQueries.phablet]: {
          borderRadius: radii[1],
        },
        [mediaQueries.tablet]: {
          padding: space[9],
        },
        [mediaQueries.desktop]: {
          padding: space[12],
        },
      }}
      aria-label={`Learn more about: ${associatedPage.name}`}
    >
      <div
        css={{
          textAlign: `center`,
          [mediaQueries.phablet]: {
            display: `flex`,
            textAlign: `left`,
          },
        }}
      >
        <div
          css={{
            order: isOdd ? 1 : false,
            flexShrink: 0,
            width: 200,
            marginLeft: `auto`,
            marginRight: `auto`,
            marginBottom: space[6],
            [mediaQueries.phablet]: {
              marginBottom: 0,
              width: `30%`,
            },
            [mediaQueries.tablet]: {
              paddingLeft: isOdd ? space[7] : false,
              paddingRight: isOdd ? false : space[7],
              width: 240,
            },
            [mediaQueries.desktop]: {
              paddingLeft: isOdd ? space[12] : false,
              paddingRight: isOdd ? false : space[12],
              width: 280,
            },
          }}
        >
          {keyvisual && (
            <LazySVGImg
              src={`${keyvisual.name}.svg`}
              alt={``}
              css={{
                width: `100%`,
                height: `auto`,
                margin: 0,
              }}
            />
          )}
        </div>
        <div>
          <Subtitle customStyles={{ fontWeight: 700 }}>
            <LinkWithArrow title={title} />
          </Subtitle>
          <div css={{ lineHeight: lineHeights.loose }}>
            <div
              dangerouslySetInnerHTML={{
                __html: text && text.childMarkdownRemark.html,
              }}
            />
            <ReadMore />
          </div>
        </div>
      </div>
    </Link>
  )
}

Card.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number,
}

export default Card
