import hex2rgba from "hex2rgba"

import { rhythm } from "./typography"
import {
  lineHeights,
  fontSizes,
  mediaQueries,
  colors,
  fonts,
  transition,
  letterSpacings,
  shadows,
  space,
  sizes,
} from "./presets"

const titleBaseStyles = {
  WebkitFontSmoothing: `antialiased`,
  textRendering: `optimizelegibility`,
  fontFamily: fonts.header,
  letterSpacing: letterSpacings.tight,
  lineHeight: 1.125,
}

const titleStyles = {
  ...titleBaseStyles,
  color: colors.black,
  fontWeight: 800,
  transition: `color ${transition.speed.default} ${transition.curve.default}`,
  [mediaQueries.tablet]: {
    fontSize: fontSizes[9],
  },
  [mediaQueries.hd]: {
    fontSize: fontSizes[10],
  },
  "& span": {
    fontWeight: `normal`,
  },
}

const subtitleStyles = {
  ...titleBaseStyles,
  fontSize: fontSizes[5],
  fontWeight: `normal`,
  [mediaQueries.tablet]: {
    fontSize: fontSizes[6],
  },
}

// gatsby-remark-autolink-headers
const anchorStyles = {
  "a.anchor": {
    color: `inherit`,
    fill: colors.lilac,
    textDecoration: `none`,
    borderBottom: `0`,
  },
}

const articleStyles = {
  // Target image captions. This is kind of a fragile selector...
  "img + em": {
    fontSize: fontSizes[1],
    lineHeight: lineHeights.dense,
    marginTop: `-${space[6]}`,
    marginBottom: space[6],
    display: `block`,
    textAlign: `center`,
    color: colors.gray.calm,
  },
  "img, .gatsby-resp-image-link": {
    boxShadow: shadows.dialog,
    marginTop: space[6],
    marginBottom: space[6],
  },
  "p, ol, ul, h1, h2, h3, h4, h5, h6": {
    maxWidth: rhythm(26),
  },
  h2: {
    marginTop: space[9],
  },
  "h3, h4": {
    marginTop: space[6],
  },
  table: {
    fontSize: fontSizes[1],
    marginTop: space[9],
    marginBottom: space[9],
  },
  ...anchorStyles,
}

const bodyCopy = {
  color: colors.gray.neutral,
  // lineHeight: lineHeights.loose,
  [mediaQueries.tablet]: {
    fontSize: fontSizes[3],
    maxWidth: rhythm(24),
  },
}

const defaultPaddingHorizontal = {
  paddingLeft: space[7],
  paddingRight: space[7],
  [mediaQueries.tablet]: {
    paddingLeft: space[7],
    paddingRight: space[7],
  },
}

const howItWorksSVGStyles = {
  "& svg path": {
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-stroke": {
    stroke: colors.howItWorks.base,
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-fill": {
    fill: colors.howItWorks.base,
  },
  "& .icon-apps-gradient-1": {
    stroke: `url(#icon-apps-gradient-1-blue)`,
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-apps-gradient-2": {
    stroke: `url(#icon-apps-gradient-2-blue)`,
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-apps-gradient-3": {
    fill: `url(#icon-apps-gradient-3-blue)`,
  },
  "& .icon-performance-gradient-1": {
    fill: `url(#icon-performance-gradient-1-blue)`,
  },
}

const smallCapsStyles = {
  color: colors.gray.neutral,
  fontSize: fontSizes[1],
  fontFamily: fonts.header,
  letterSpacing: letterSpacings.tracked,
  textTransform: `uppercase`,
}

const howItWorksSVGHoverStyles = {
  "& svg path": {
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-stroke": {
    stroke: colors.gatsby,
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-fill": {
    fill: colors.gatsby,
  },
  "& .icon-apps-gradient-1": {
    stroke: `url(#icon-apps-gradient-1)`,
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-apps-gradient-2": {
    stroke: `url(#icon-apps-gradient-2)`,
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-apps-gradient-3": {
    fill: `url(#icon-apps-gradient-3)`,
  },
  "& .icon-performance-gradient-1": {
    fill: `url(#icon-performance-gradient-1)`,
  },
}

const linkStyles = {
  position: `relative`,
  fontWeight: 500,
  textDecoration: `none`,
  borderBottom: `1px solid ${colors.lilac}`,
  transition: `all ${transition.speed.default} ${transition.curve.default}`,
  "&:hover": {
    borderBottomColor: `transparent`,
  },
}

const guideContentBlockStyles = {
  marginTop: space[12],
  marginBottom: space[12],
  [mediaQueries.phablet]: {
    "& ul, & ol": {
      marginLeft: 0,
    },
  },
  "& a": {
    color: colors.gatsby,
    fontWeight: 400,
    ...linkStyles,
  },
}

const guideContainer = {
  marginLeft: `auto`,
  marginRight: `auto`,
  [mediaQueries.phablet]: {
    width: `90%`,
    maxWidth: rhythm(26),
  },
  [mediaQueries.desktop]: {
    maxWidth: rhythm(28),
  },
  ...defaultPaddingHorizontal,
}

const defaultPadding = {
  paddingBottom: space[6],
  paddingLeft: rhythm(8),
}

const guidePaddingBottom = {
  paddingBottom: space[9],
  [mediaQueries.phablet]: {
    paddingBottom: space[13],
  },
}

const gridBackground = {
  background: hex2rgba(colors.howItWorks.bright, 0.5),
}

const withHowItWorksGridBorder = {
  position: `relative`,
  "&:after": {
    ...gridBackground,
    content: `" "`,
    display: `block`,
    height: 1,
    left: `-100vw`,
    right: `-100vw`,
    bottom: 0,
    position: `absolute`,
  },
}

const primaryButtonHoverStyle = {
  background: colors.gatsby,
}

const primaryButtonDefaultStyles = {
  background: colors.gatsby,
  ":hover": {
    ...primaryButtonHoverStyle,
  },
  ":focus": {
    outline: 0,
  },
}

const container = {
  position: `relative`,
  zIndex: 1,
  margin: `0 auto`,
  width: `100%`,
  maxWidth: `${sizes.layoutWidth}rem`,
  ...defaultPaddingHorizontal,
  [mediaQueries.phablet]: {
    width: `90%`,
  },
}

export {
  linkStyles,
  howItWorksSVGStyles,
  howItWorksSVGHoverStyles,
  titleStyles,
  subtitleStyles,
  guideContentBlockStyles,
  guideContainer,
  guidePaddingBottom,
  defaultPadding,
  defaultPaddingHorizontal,
  withHowItWorksGridBorder,
  bodyCopy,
  primaryButtonDefaultStyles,
  primaryButtonHoverStyle,
  gridBackground,
  anchorStyles,
  articleStyles,
  smallCapsStyles,
  container,
}
