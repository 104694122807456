import React from "react"
import PropTypes from "prop-types"

import Title from "../hero/title"
import FeaturesCTA from "../features/cta"
import { colors } from "../../utils/presets"
import { rhythm } from "../../utils/typography"
import { bodyCopy } from "../../utils/styles"

const FullWidthCentered = ({ title, text, associatedPage, linkText }) => (
  <div
    css={{
      textAlign: `center`,
      padding: `80px 0`,
    }}
  >
    {title && (
      <Title
        customStyles={{
          color: colors.gatsby,
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(20),
        }}
      >
        {title}
      </Title>
    )}
    {text && (
      <div
        css={{
          ...bodyCopy,
          maxWidth: rhythm(24),
          marginLeft: `auto`,
          marginRight: `auto`,
        }}
        dangerouslySetInnerHTML={{
          __html: text.childMarkdownRemark.html,
        }}
      />
    )}
    <FeaturesCTA associatedPage={associatedPage} linkText={linkText} />
  </div>
)

FullWidthCentered.propTypes = {
  body: PropTypes.object,
  title: PropTypes.string,
  associatedPage: PropTypes.object,
  linkText: PropTypes.string,
  text: PropTypes.object,
}

export default FullWidthCentered
