import React from "react"
import PropTypes from "prop-types"
import hex2rgba from "hex2rgba"
import { keyframes, injectGlobal } from "react-emotion"

import NavItem from "./item"
import { getNavItems, getLink } from "../../utils/navigation"
import { sizes, colors, space } from "../../utils/presets"
import { container } from "../../utils/styles"

const slideFade = keyframes({
  "0%": {
    opacity: 0,
    transform: `translate(-${space[5]})`,
  },
  "50%": {
    opacity: 1,
  },
  "100%": {
    opacity: 1,
    transform: `translate(0)`,
  },
})

class Navigation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobileNavOpen: false,
    }
  }

  updateStateFromNav = props => {
    this.setState({
      ...props,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      isMobileNavOpen: nextProps.isMobileNavOpen,
    })
  }

  render() {
    const { isInverted, bpToggleNavigation } = this.props
    const navItems = getNavItems(this.props.navItems)

    injectGlobal(`
      .nav-open li {
        opacity: 0;
      }

      ${navItems
        .map(
          (navItem, i) =>
            `.nav-open li:nth-child(${i + 1}) {
            animation: ${slideFade} .2s .${1 + i * 10}s 1 forwards;
          }`
        )
        .join(``)}
    `)

    return (
      <div
        css={{
          display: this.state.isMobileNavOpen ? `block` : `none`,
          position: `fixed`,
          overflowY: `auto`,
          WebkitOverflowScrolling: `touch`,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 0,
          background: hex2rgba(colors.night, 0.975),
          padding: `${sizes.headerHeight} ${space[10]} ${sizes.headerHeight}`,
          alignItems: `center`,
          textAlign: `right`,
          [bpToggleNavigation]: {
            textAlign: `left`,
            display: `flex`,
            background: `none`,
            position: `static`,
            top: `auto`,
            left: `auto`,
            right: `auto`,
            bottom: `auto`,
            margin: 0,
            padding: 0,
            flexGrow: 0,
            marginLeft: `auto`,
            overflow: `visible`,
          },
        }}
      >
        <ul
          id="gatsby-nav"
          css={{
            listStyle: `none`,
            margin: `0 0 0 auto`,
            padding: `0 ${space[4]}`,
            ...container,
            [bpToggleNavigation]: {
              width: `auto`,
              padding: 0,
              display: `flex`,
              position: `static`,
              flexGrow: 0,
            },
          }}
        >
          {navItems.map((navItem, i) => (
            <NavItem
              linkTo={getLink(navItem)}
              isInverted={isInverted}
              sections={navItem.sections}
              updateParentState={this.updateStateFromNav.bind(this)}
              key={i}
              isLast={navItems.length === i + 1}
              bpToggleNavigation={bpToggleNavigation}
            >
              {navItem.name}
            </NavItem>
          ))}
        </ul>
      </div>
    )
  }
}

Navigation.propTypes = {
  bpToggleNavigation: PropTypes.string,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      parentPage: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
      }),
    })
  ).isRequired,
  isMobileNavOpen: PropTypes.bool.isRequired,
  isInverted: PropTypes.bool,
}

export default Navigation
