import React from "react"
import PropTypes from "prop-types"
import { keyframes } from "react-emotion"
import hex2rgba from "hex2rgba"
import deepmerge from "deepmerge"

import LazySVGImg from "./lazy-svg"
import {
  colors,
  mediaQueries,
  fontSizes,
  lineHeights,
  radii,
  shadows,
  space,
} from "../utils/presets"

const breakpoint = mediaQueries.tablet
const databaseConnectorColor = `#ff8091`
const cmsConnectorColor = `#ffc466`
const filesystemConnectorColor = `#f9ef62`
const reactConnectorColor = `#91e3f8`
const cdnConnectorColor = colors.purple[`200`]
const userConnectorColor = colors.purple[`200`]
const debug = 0

const pulseRing = keyframes({
  "0%": { transform: `scale(.33)`, opacity: 0.75 },
  "80%, 100%": { opacity: 0 },
})

const pulseDot = keyframes({
  "0%": { transform: `scale(.8)` },
  "50%": { transform: `scale(1)` },
  "100%": { transform: `scale(.8)` },
})

const styles = {
  block: {
    display: `flex`,
    alignItems: `center`,
    background: debug ? `green` : false,
    width: `100%`,
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: `row`,
    alignSelf: `flex-start`,
    [breakpoint]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: space[3],
      paddingTop: space[3],
    },
  },
  outerBlock: {
    [breakpoint]: {
      width: `33.3333333%`,
    },
  },
  cliAndCode: {
    [breakpoint]: {
      background: debug ? `rgba(255,255,0,.25)` : false,
      flexGrow: 1,
      flexShrink: 0,
      // width: `30%`,
      display: `flex`,
      flexDirection: `column`,
    },
  },
  pulse: {
    position: `absolute`,
    left: `50%`,
    top: `50%`,
    transform: `translateX(-50%) translateY(-50%)`,
    width: space[14],
    height: space[14],
    zIndex: -1,
    "&:before": {
      content: `" "`,
      position: `absolute`,
      display: `block`,
      width: `300%`,
      height: `300%`,
      boxSizing: `border-box`,
      marginLeft: `-100%`,
      marginTop: `-100%`,
      borderRadius: radii[6],
      backgroundColor: colors.ui.bright,
      animation: `${pulseRing} 3.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite`,
    },
    "&:after": {
      content: `" "`,
      position: `absolute`,
      left: 0,
      top: 0,
      display: `block`,
      width: `100%`,
      height: `100%`,
      backgroundColor: colors.gatsby,
      borderRadius: radii[6],
      boxShadow: shadows.raised,
      animation: `${pulseDot} 3.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite`,
    },
  },
}

const verticalConnectorLinesLength = 100

const Label = ({ label, customStyles }) => (
  <div
    css={{
      fontSize: fontSizes[0],
      lineHeight: lineHeights.dense,
      width: space[12],
      ...customStyles,
      //   height: 60,
      //   alignSelf: `flex-end`,
    }}
  >
    {label}
  </div>
)

Label.propTypes = {
  label: PropTypes.string,
  customStyles: PropTypes.object,
}

const IconWithLabel = ({
  label,
  icon,
  labelPosition = `top`,
  customStyles,
}) => (
  <div
    css={deepmerge(
      {
        background: debug ? hex2rgba(`#f63`, 0.25) : false,
        textAlign: `center`,
        position: `relative`,
        zIndex: 1,
        [breakpoint]: {
          flexShrink: 0,
          order: -10,
          display: `flex`,
          flexDirection: `column`,
        },
      },
      customStyles ? customStyles : {}
    )}
  >
    <Label
      label={label}
      customStyles={{
        order: labelPosition === `top` ? false : 1,
        marginLeft: `auto`,
        marginRight: `auto`,
        marginBottom: space[6],
        [breakpoint]: {
          marginBottom: 0,
        },
      }}
    />
    <div
      css={{
        background: debug ? hex2rgba(`#99f`, 0.5) : false,
        flexGrow: 0,
      }}
    >
      <LazySVGImg
        src={icon}
        alt=""
        css={{
          background: debug ? hex2rgba(`#996`, 0.25) : false,
          display: `block`,
          margin: `0 auto`,
          marginTop: space[1],
          marginBottom: label === `` ? space[6] : false,
          [breakpoint]: {
            marginTop: labelPosition === `top` ? space[1] : 0,
            marginBottom: labelPosition === `top` ? 0 : space[1],
          },
        }}
      />
    </div>
  </div>
)

IconWithLabel.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  labelPosition: PropTypes.string,
  customStyles: PropTypes.object,
}

const IconsWithLabel = ({
  label,
  icon,
  customStyles,
  labelPosition = `top`,
}) => (
  <div
    css={{
      flexShrink: 0,
      [breakpoint]: { display: `flex`, flexDirection: `column` },
      ...customStyles,
    }}
  >
    <div
      css={{
        width: 68,
        background: debug ? `${hex2rgba(`#f0f`, 0.2)}` : false,
        flexGrow: 0,
        position: `relative`,
        textAlign: `center`,
        [breakpoint]: {
          display: `flex`,
          flexDirection: `column`,
        },
      }}
    >
      <Label
        label={label}
        customStyles={{
          width: `auto`,
          marginBottom: space[6],
          [breakpoint]: {
            marginBottom: 0,
            order: labelPosition === `top` ? false : 1,
          },
        }}
      />
      <div
        css={{
          position: `relative`,
          [breakpoint]: {
            marginTop: labelPosition === `top` ? space[1] : false,
            marginBottom: labelPosition === `top` ? false : space[1],
          },
        }}
      >
        <LazySVGImg src={icon} css={{ margin: `0`, display: `block` }} alt="" />
      </div>
    </div>
  </div>
)

IconsWithLabel.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  labelPosition: PropTypes.string,
  customStyles: PropTypes.object,
}

const ConnectorWithLabel = ({
  customStyles,
  color = colors.ui.bright,
  direction = `up`,
}) => (
  <div
    css={{
      ...customStyles,
      background: debug ? `rgba(0,150,255,0.25)` : false,
      [breakpoint]: {
        display: `flex`,
        flexDirection: `column`,
      },
    }}
  >
    <div
      css={{
        height: 10,
        [breakpoint]: {
          display: `flex`,
          width: `100%`,
        },
      }}
    >
      <div
        css={{
          width: 1,
          background: color,
          height: `100%`,
          position: `relative`,
          margin: `0 auto`,
          "&:after": {
            background: color,
            content: `" "`,
            height: verticalConnectorLinesLength,
            width: 1,
            position: `absolute`,
          },
          [breakpoint]: {
            marginLeft: 24,
            "&:after": {
              display: `none`,
            },
          },
        }}
      />
      <div
        css={{
          [breakpoint]: {
            position: `relative`,
            height: 1,
            background: color,
            flexGrow: 1,
            marginTop: direction === `up` ? 9 : false,
            "&:after": {
              background: color,
              content: `" "`,
              height: 1,
              width: 40,
              position: `absolute`,
              bottom: 0,
              left: `auto`,
              right: 0,
            },
            "&:before": {
              background: color,
              content: `" "`,
              height: 20,
              width: 1,
              position: `absolute`,
              bottom: direction === `up` ? -20 : 0,
              left: `auto`,
              right: 0,
              transform:
                direction === `up` ? `rotate(-45deg)` : `rotate(45deg)`,
              transformOrigin: direction === `up` ? `0 0` : `100% 100%`,
            },
          },
        }}
      />
    </div>
  </div>
)

ConnectorWithLabel.propTypes = {
  label: PropTypes.string,
  customStyles: PropTypes.object,
  color: PropTypes.string,
  direction: PropTypes.string,
}

const Connector = ({ color = colors.ui.bright }) => (
  <div
    css={{
      flexGrow: 1,
      height: 40,
      background: debug ? `green` : false,
      [breakpoint]: { height: `auto`, width: `100%` },
    }}
  >
    <div
      css={{
        width: 1,
        background: color,
        height: `100%`,
        [breakpoint]: {
          height: 1,
          width: `100%`,
        },
      }}
    />
  </div>
)

Connector.propTypes = {
  color: PropTypes.string,
}

const ConnectorCDN = ({
  color = cdnConnectorColor,
  width = `50%`,
  cdnIcon = `big`,
  children,
}) => (
  <div
    css={{
      flexGrow: 1,
      flexShrink: 0,
      position: `relative`,
      display: cdnIcon === `big` ? `flex` : `none`,
      alignItems: `center`,
      flexDirection: `column`,
      [breakpoint]: {
        display: `flex`,
        height: `auto`,
        width: `100%`,
        flexDirection: `row`,
      },
    }}
  >
    <div
      css={{
        width: 1,
        background: color,
        height: 20,
        position: `relative`,
        marginBottom: space[6],
        [breakpoint]: {
          height: 1,
          width: width,
          flexShrink: 1,
          marginBottom: 0,
          marginRight: space[3],
          "&:after": {
            content: `" "`,
            background: color,
            height: 1,
            position: `absolute`,
            left: -50,
            right: 0,
          },
        },
      }}
    />
    <CdnWithUser cdnIconSize={cdnIcon}>{children}</CdnWithUser>
    {cdnIcon === `big` && (
      <div
        css={{
          width: 1,
          background: color,
          height: 60,
          position: `relative`,
          marginTop: 30,
          marginBottom: 10,
          [breakpoint]: {
            height: 1,
            marginTop: 0,
            marginBottom: 0,
            width: width,
            flexShrink: 0,
            // "&:after": {
            //   content: `" "`,
            //   height: 1,
            //   background: userConnectorColor,
            //   position: `absolute`,
            //   right: -20,
            //   width: 20,
            // },
          },
        }}
      />
    )}
  </div>
)

ConnectorCDN.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  cdnIcon: PropTypes.string,
  children: PropTypes.node,
}

const CdnWithUser = ({ cdnIconSize, children }) => (
  <div
    css={{
      background: debug ? `${hex2rgba(`#6ff`, 0.2)}` : false,
      flexShrink: 0,
      position: `relative`,
    }}
  >
    {children}
    <LazySVGImg
      src={cdnIconSize === `big` ? `diagram/cdn.svg` : `diagram/cdn-mini.svg`}
      css={{ margin: 0, display: `block`, position: `relative`, zIndex: 1 }}
      alt=""
    />
    {cdnIconSize === `big` && (
      <Label
        customStyles={{
          position: `absolute`,
          bottom: -20,
          left: `50%`,
          transform: `translateX(-15%)`,
        }}
        label="CDN"
      />
    )}
  </div>
)

CdnWithUser.propTypes = {
  cdnIconSize: PropTypes.string,
  children: PropTypes.node,
}

const UserCircle = ({ width = 16, customStyles, rotate, size = 16 }) => (
  <div
    css={{
      background: debug ? `rgba(200,100,50,0.2)` : false,
      width: width,
      height: 1,
      position: `absolute`,
      top: `50%`,
      transformOrigin: `0% 0%`,
      transform: `rotate(${rotate}deg)`,
      ...customStyles,
      "&:after": {
        content: `" "`,
        position: `absolute`,
        top: 0,
        left: 0,
        right: 0,
        height: 1,
        background: userConnectorColor,
      },
    }}
  >
    <div
      css={{
        height: size,
        width: size,
        position: `absolute`,
        zIndex: 1,
        right: `-${size / 2}`,
        top: `-${size / 2}`,
        transformOrigin: `50% 50%`,
        transform: `rotate(${rotate * -1}deg)`,
      }}
    >
      <LazySVGImg src="diagram/user-new-small.svg" alt="" />
    </div>
  </div>
)

UserCircle.propTypes = {
  width: PropTypes.number,
  customStyles: PropTypes.object,
  rotate: PropTypes.number,
  size: PropTypes.number,
}

const Diagram = () => (
  <div
    css={{
      background: debug ? `yellow` : false,
      marginBottom: space[13],
      [breakpoint]: {
        display: `flex`,
        maxWidth: 1040,
        marginLeft: `auto`,
        marginRight: `auto`,
      },
    }}
  >
    <div
      css={{
        background: debug ? `pink` : false,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        [breakpoint]: {
          width: `33.3333333%`,
          flexDirection: `row`,
        },
      }}
    >
      <div
        css={{
          background: debug ? `red` : false,
          display: `flex`,
          flexDirection: `row`,
          justifyContent: `center`,
          [breakpoint]: {
            width: `50%`,
            flexDirection: `column`,
          },
        }}
      >
        <div
          css={{
            background: debug ? `rgba(255,255,155,0.5)` : false,
            ...styles.block,
            position: `relative`,
            "&:after": {
              content: `" "`,
              top: `auto`,
              left: `50%`,
              width: `50%`,
              position: `absolute`,
              bottom: `-${verticalConnectorLinesLength - 10}`,
              height: 1,
              background: databaseConnectorColor,
            },
            [breakpoint]: {
              "&:after": {
                display: `none`,
              },
            },
          }}
        >
          <div
            css={deepmerge(styles.cliAndCode, {
              margin: `0 auto`,
              [breakpoint]: {
                paddingTop: 10,
                paddingLeft: 30,
              },
            })}
          >
            <IconsWithLabel
              label="Database"
              icon="diagram/database.svg"
              customStyles={{ textAlign: `left` }}
            />
            <ConnectorWithLabel label="" color={databaseConnectorColor} />
          </div>
        </div>
        <div
          css={deepmerge(styles.block, {
            flexDirection: `column`,
            [breakpoint]: {
              flexDirection: `row`,
            },
          })}
        >
          <IconWithLabel
            label="CMS"
            icon="diagram/cms.svg"
            labelPosition="bottom"
          />
          <Connector color={cmsConnectorColor} />
        </div>
        <div
          css={deepmerge(styles.block, {
            background: debug ? `rgba(170,200,235,0.5)` : false,
            position: `relative`,
            "&:after": {
              content: `" "`,
              top: `auto`,
              left: 0,
              width: `50%`,
              position: `absolute`,
              bottom: `-${verticalConnectorLinesLength - 10}`,
              height: 1,
              background: filesystemConnectorColor,
            },
            [breakpoint]: {
              "&:after": {
                display: `none`,
              },
            },
          })}
        >
          <div
            css={deepmerge(
              {
                margin: `0 auto`,
                background: debug ? `orange` : false,
                order: -1,
                [breakpoint]: {
                  paddingBottom: 10,
                  background: debug ? `blue` : false,
                  order: 3,
                  paddingLeft: 30,
                },
              },
              styles.cliAndCode
            )}
          >
            <IconsWithLabel
              label="Filesystem"
              icon="diagram/filesystem.svg"
              customStyles={{ textAlign: `left` }}
              labelPosition="bottom"
            />
            <ConnectorWithLabel
              label=""
              customStyles={{ order: -1 }}
              color={filesystemConnectorColor}
              direction="down"
            />
          </div>
        </div>
      </div>
      <div
        css={{
          width: `50%`,
          alignSelf: `center`,
        }}
      >
        <div
          css={{
            background: debug ? `red` : false,
          }}
        >
          <div
            css={deepmerge(styles.block, {
              flexDirection: `column`,
              [breakpoint]: {
                flexDirection: `row`,
              },
            })}
          >
            <IconWithLabel
              label=""
              icon="diagram/react.svg"
              labelPosition="bottom"
            />
            <Connector color={reactConnectorColor} />
          </div>
        </div>
      </div>
    </div>
    <div
      css={{
        background: debug ? `yellow` : false,
        ...styles.outerBlock,
        alignItems: `center`,
        justifyContent: `center`,
        display: `flex`,
        position: `relative`,
        zIndex: 1,
        minHeight: 200,
        // @todo merge w/ styles.outerBlock above
        // [breakpoint]: {
        //   height: `auto`,
        // },
      }}
    >
      <LazySVGImg
        src="monogram.svg"
        css={{
          maxWidth: space[9],
          margin: `0`,
          alignSelf: `center`,
          display: `block`,
        }}
        alt=""
      />
      <div
        css={{
          ...styles.pulse,
        }}
      />
      <div
        css={{
          zIndex: -2,
          border: `1px solid ${colors.ui.light}`,
          borderRadius: radii[6],
          position: `absolute`,
          background: colors.white,
          width: 200,
          height: 200,
          left: `50%`,
          top: `50%`,
          transform: `translateY(-50%) translateX(-50%)`,
          [breakpoint]: {
            height: 0,
            width: `100%`,
            paddingBottom: `100%`,
          },
        }}
      />
    </div>
    <div
      css={{
        background: debug ? `skyblue` : false,
        ...styles.outerBlock,
        display: `flex`,
        flexDirection: `column`,
        flexGrow: 1,
        [breakpoint]: {
          flexDirection: `row`,
        },
      }}
    >
      <div
        css={{
          background: debug ? `rgba(150, 0, 150, 0.4)` : false,
          flexGrow: 1,
          display: `flex`,
          justifyContent: `center`,
          [breakpoint]: {
            flexDirection: `column`,
          },
        }}
      >
        <ConnectorCDN>
          <div
            css={{
              display: `none`,
              [breakpoint]: {
                display: `block`,
              },
            }}
          >
            <UserCircle
              width={140}
              rotate={45}
              size={24}
              customStyles={{
                left: 10,
              }}
            />
            <UserCircle
              width={140}
              rotate={-45}
              size={24}
              customStyles={{
                left: 10,
              }}
            />
          </div>
          <div
            css={{
              display: `block`,
              [breakpoint]: {
                display: `none`,
              },
            }}
          >
            <UserCircle
              width={120}
              rotate={135}
              size={24}
              customStyles={{
                left: 10,
              }}
            />
            <UserCircle
              width={120}
              rotate={180}
              size={24}
              customStyles={{
                left: 10,
              }}
            />
            <UserCircle
              width={120}
              rotate={0}
              size={24}
              customStyles={{
                left: 10,
              }}
            />
            <UserCircle
              width={120}
              rotate={45}
              size={24}
              customStyles={{
                left: 10,
              }}
            />
          </div>
        </ConnectorCDN>
      </div>
      <div
        css={{
          background: debug ? `rgba(150, 150, 0, 0.2)` : false,
          [breakpoint]: { alignSelf: `center` },
        }}
      >
        <IconWithLabel
          label="User"
          icon="diagram/user-new.svg"
          labelPosition="bottom"
        />
      </div>
    </div>
  </div>
)

export default Diagram
