import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FaGraduationCap } from "react-icons/fa"

import Container from "../container"
import { getNavItems, getLink } from "../../utils/navigation"
import {
  mediaQueries,
  transition,
  colors,
  fonts,
  fontSizes,
  letterSpacings,
  space,
} from "../../utils/presets"

const gutter = space[4]

const FooterBlock = ({ title, children, isInverted }) => (
  <div
    css={{
      boxSizing: `border-box`,
      flex: `0 0 100%`,
      margin: `${space[6]} 0`,
      padding: `0 ${gutter}`,
      "@media (min-width: 380px)": {
        flex: `0 0 50%`,
      },
      [mediaQueries.tablet]: {
        flex: `1 0 auto`,
        justifyContent: `space-between`,
      },
    }}
  >
    <h2
      css={{
        fontSize: fontSizes[0],
        color: isInverted ? colors.purple[`300`] : false,
        fontFamily: fonts.system,
        letterSpacing: letterSpacings.tracked,
        marginTop: 0,
        marginBottom: space[6],
        textTransform: `uppercase`,
      }}
    >
      {title}
    </h2>
    {children}
  </div>
)

const withIconHoverStyles = {
  position: `relative`,
  "& span": {
    opacity: 0,
    position: `absolute`,
    left: -20,
    top: -1,
    transition: `all ${transition.speed.default} ${transition.curve.default}`,
  },
  "&:hover span": {
    opacity: 1,
  },
}

FooterBlock.propTypes = {
  title: PropTypes.string.isRequired,
  isInverted: PropTypes.bool,
}

const FooterNavigation = ({ navItems, isInverted }) => {
  const weightedNavItems = getNavItems(navItems)
  const howItWorksPageTitle = `How It Works`

  return (
    <Container>
      <div
        css={{
          display: `flex`,
          flexGrow: 1,
          flexWrap: `wrap`,
          margin: `${space[6]} -${gutter} 0`,
          padding: `${space[6]} 0 ${space[9]}`,
          width: `100%`,
          "& a": {
            fontSize: fontSizes[1],
            margin: `${space[1]} 0`,
            color: isInverted ? `rgba(255,255,255,0.8) ` : colors.gray.calm,
            display: `block`,
            textDecoration: `none`,
            transition: `all ${transition.speed.default} ${
              transition.curve.default
            }`,
            "&:hover": {
              color: isInverted ? colors.accent : colors.gatsby,
            },
          },
        }}
      >
        <FooterBlock title="Product" isInverted={isInverted}>
          <Link to="/why-gatsby/">Why Gatsby?</Link>
          <Link to="/how-it-works/">How It Works</Link>
          <Link to="/integrations/">Integrations</Link>
        </FooterBlock>
        <FooterBlock title={howItWorksPageTitle} isInverted={isInverted}>
          {weightedNavItems.map(
            navItem =>
              navItem.name === howItWorksPageTitle &&
              navItem.sections &&
              navItem.sections.map((section, i) => (
                <Link to={getLink(section)} key={i}>
                  {section.name}
                </Link>
              ))
          )}
          <Link
            to="/guides/"
            css={{
              "&&": {
                [mediaQueries.tablet]: {
                  marginTop: space[6],
                },
                ...withIconHoverStyles,
              },
            }}
          >
            <span>
              <FaGraduationCap glyphOrientationVertical="center" />
            </span>
            All Guides
          </Link>
        </FooterBlock>
        <FooterBlock title="Docs & Help" isInverted={isInverted}>
          <a href="https://www.gatsbyjs.org/docs/">Documentation</a>
          <a href="https://www.gatsbyjs.org/tutorial/">Tutorial</a>
          <a href="https://www.gatsbyjs.org/blog/">Blog</a>
          <a href="https://www.gatsbyjs.org/community/">Community</a>
        </FooterBlock>
        <FooterBlock title="Company" isInverted={isInverted}>
          <Link to="/about/">About Us</Link>
          <Link to="/contact-us/">Contact Us</Link>
          <Link to="/training/">Training</Link>
        </FooterBlock>
        <div
          css={{
            padding: `${space[12]} ${gutter} ${space[9]}`,
            width: `100%`,
            "& a": {
              display: `inline-block`,
            },
          }}
        >
          <div>
            <Link to="/terms-of-use" css={{ "&&": { marginRight: space[4] } }}>
              Terms of Use
            </Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
          <small
            css={{
              fontSize: fontSizes[1],
              color: isInverted ? colors.gatsby : colors.gray.bright,
            }}
          >
            © {new Date().getFullYear()} Gatsby, Inc.
          </small>
        </div>
      </div>
    </Container>
  )
}

FooterNavigation.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      parentPage: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
      }),
    })
  ).isRequired,
  isInverted: PropTypes.bool,
}

export default FooterNavigation
