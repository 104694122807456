import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { injectGlobal } from "react-emotion"

import config from "../config"
import { colors } from "../utils/presets"

import interRegularWoff2 from "../assets/fonts/inter/Inter-Regular.woff2"
import interRegularWoff from "../assets/fonts/inter/Inter-Regular.woff"

import interItalicWoff2 from "../assets/fonts/inter/Inter-Italic.woff2"
import interItalicWoff from "../assets/fonts/inter/Inter-Italic.woff"

import interBoldWoff2 from "../assets/fonts/inter/Inter-Bold.woff2"
import interBoldWoff from "../assets/fonts/inter/Inter-Bold.woff"

import "../assets/fonts/futura-pt/Webfonts/futurapt_book_macroman/stylesheet.css"
import "../assets/fonts/futura-pt/Webfonts/futurapt_demi_macroman/stylesheet.css"
import "../assets/fonts/futura-pt/Webfonts/futurapt_bold/MyFontsWebfontsKit.css"

injectGlobal`
/* Inter UI */
@font-face {
  font-family: "Inter UI";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("${interRegularWoff2}") format("woff2"),
    url("${interRegularWoff}") format("woff");
}

@font-face {
  font-family: "Inter UI";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("${interItalicWoff2}") format("woff2"),
    url("${interItalicWoff}") format("woff");
}

@font-face {
  font-family: "Inter UI";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("${interBoldWoff2}") format("woff2"),
    url("${interBoldWoff}") format("woff");
}
`

const Layout = ({ children, background }) => (
  <Fragment>
    <Helmet
      defaultTitle={config.title}
      titleTemplate={`%s | ${config.title}`}
      meta={[
        {
          name: `description`,
          content: config.description,
        },
        {
          name: `keywords`,
          content: config.keywords,
        },
        { name: `twitter:site`, content: config.twitter },
        { name: `og:type`, content: `website` },
        { name: `og:site_name`, content: config.title },
      ]}
    >
      <html lang="en" css={{ background: background ? background : false }} />
    </Helmet>
    <div
      css={{
        background: background ? background : colors.white,
        position: `relative`,
      }}
    >
      {children}
    </div>
  </Fragment>
)

Layout.propTypes = {
  data: PropTypes.shape({
    nav: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  page: PropTypes.object,
  location: PropTypes.object,
}

export default Layout
