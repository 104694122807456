import gray from "gray-percentage"
import { palette, colors } from "../../dashboard/utils/presets/colors"

export default {
  ...palette,
  ...colors,
  night: palette.purple[`900`],
  warning: palette.red[`600`],
  ui: {
    bright: palette.purple[`200`],
    light: palette.purple[`100`],
  },
  gray: {
    dark: gray(8, 270),
    copy: gray(12, 270),
    neutral: gray(28, 270),
    calm: gray(46, 270),
    bright: gray(76, 270),
    light: gray(86, 270),
    faint: gray(96, 270),
  },
  howItWorks: {
    dark: palette.blue[`900`],
    base: palette.blue[`900`],
    bright: palette.blue[`100`],
    light: palette.blue[`50`],
  },
  react: {
    dark: `#1c3e47`,
  },
}
