import React from "react"
import PropTypes from "prop-types"

import Container from "./container"
import Navigation from "./navigation"
import Logo from "../components/core/logo"
import Hamburger from "./navigation/hamburger"
import { mediaQueries, sizes, fonts } from "../utils/presets"

const toggleNavigation = 850
const bpToggleNavigation = `@media (min-width: ${toggleNavigation}px)`

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobileNavOpen: false,
    }
    this.updateDimensions = this.updateDimensions.bind(this)
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener(`resize`, this.updateDimensions)
  }

  updateDimensions() {
    this.setState({
      width: window.innerWidth,
      isMobileNavOpen:
        window.innerWidth > toggleNavigation
          ? false
          : this.state.isMobileNavOpen,
    })
  }

  updateStateFromNav = props => {
    this.setState({
      ...props,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      isMobileNavOpen: nextProps.isMobileNavOpen,
    })
  }

  componentWillUnmount() {
    window.removeEventListener(`resize`, this.updateDimensions)
  }

  render() {
    const { isMobileNavOpen } = this.state
    const { isInverted, navItems } = this.props

    return (
      <div
        css={{
          position: `relative`,
          zIndex: 100,
        }}
      >
        <Container>
          <div
            css={{
              alignItems: `center`,
              display: `flex`,
              fontFamily: fonts.system,
              flexBasis: `100%`,
              height: sizes.headerHeight,
              [mediaQueries.phablet]: {
                paddingTop: sizes.headerPaddingTopDesktop,
              },
            }}
            className={isMobileNavOpen ? `nav-open` : `nav`}
          >
            <Logo
              isInverted={isInverted}
              isMobileNavOpen={isMobileNavOpen}
              updateParentState={this.updateStateFromNav.bind(this)}
            />
            <Hamburger
              isInverted={isInverted}
              isMobileNavOpen={isMobileNavOpen}
              updateParentState={this.updateStateFromNav.bind(this)}
              bpToggleNavigation={bpToggleNavigation}
            />
            <Navigation
              isInverted={isInverted}
              isMobileNavOpen={isMobileNavOpen}
              navItems={navItems}
              updateParentState={this.updateStateFromNav.bind(this)}
              bpToggleNavigation={bpToggleNavigation}
            />
          </div>
        </Container>
      </div>
    )
  }
}

Header.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape().isRequired,
    }).isRequired
  ).isRequired,
  isMobileNavOpen: PropTypes.bool.isRequired,
  isInverted: PropTypes.bool,
}

Header.defaultProps = {
  isMobileNavOpen: false,
}

export default Header
