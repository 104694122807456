import React from "react"
import PropTypes from "prop-types"
import hex2rgba from "hex2rgba"
import { MdArrowForward } from "react-icons/md"

import { mediaQueries, radii, colors, fontSizes, space } from "../utils/presets"

const ReadMore = ({ customStyles }) => (
  <div
    css={{
      alignItems: `center`,
      background: colors.gatsby,
      border: `1px solid ${hex2rgba(colors.howItWorks.bright, 0.275)}`,
      borderRadius: radii[2],
      color: colors.white,
      display: `inline-flex`,
      fontSize: fontSizes[1],
      padding: `${space[2]} ${space[2]} ${space[2]} ${space[3]}`,
      marginTop: space[6],
      whiteSpace: `nowrap`,
      [mediaQueries.desktop]: {
        marginTop: space[9],
      },
      ...customStyles,
    }}
    className="read-more"
  >
    Learn more
    {` `}
    <MdArrowForward css={{ marginLeft: space[1] }} />
  </div>
)

ReadMore.propTypes = {
  customStyles: PropTypes.object,
}

export default ReadMore
