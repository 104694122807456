import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { Dropdown, DropdownItem } from "./dropdown"
import { getLink } from "../../utils/navigation"
import {
  transition,
  sizes,
  fontSizes,
  fonts,
  colors,
  space,
} from "../../utils/presets"

const navItemStyles = {
  textDecoration: `none`,
  color: colors.white,
  fontFamily: fonts.header,
  fontWeight: `normal`,
  transition: `opacity ${transition.speed.default}`,
  WebkitFontSmoothing: `antialiased`,
  fontSize: fontSizes[6],
  "&.nav-item-active": {
    color: colors.accent,
    "&.is-inverted": {
      color: colors.accent,
      "& span:after": {
        background: `linear-gradient(45deg, ${colors.accent}, ${
          colors.accent
        })`,
      },
    },
  },
  "&:hover": {
    opacity: 0.8,
  },
}

class NavItem extends React.Component {
  constructor(props) {
    super(props)

    this.handleOnClick = this._handleOnClick.bind(this)

    this.state = {
      isMobileNavOpen: false,
    }
  }

  _handleOnClick = () => {
    this.props.updateParentState({
      isMobileNavOpen: !this.state.isMobileNavOpen,
    })
  }

  render() {
    const {
      sections,
      isInverted,
      linkTo,
      children,
      isLast,
      bpToggleNavigation,
    } = this.props

    return (
      <li
        css={{
          [bpToggleNavigation]: {
            textAlign: `left`,
            display: `inline-block`,
            margin: 0,
            padding: `0 ${space[3]}`,
            paddingRight: isLast ? 0 : false,
            color: isInverted ? `rgba(255,255,255,0.8)` : false,
            position: `relative`,
            WebkitFontSmoothing: isInverted ? `antialiased` : false,
            ":hover .child": {
              display: `inline-block`,
            },
          },
        }}
      >
        <Link
          to={linkTo}
          css={{
            ...navItemStyles,
            [bpToggleNavigation]: {
              fontSize: fontSizes[1],
              display: `block`,
              fontFamily: fonts.system,
              fontWeight: `normal`,
              color: `inherit`,
              textDecoration: `none`,
              lineHeight: `calc(2 * ${sizes.headerHeight} / 3)`,
              position: `relative`,
              "& span": {
                position: `relative`,
              },
              "&.nav-item-active": {
                color: colors.gatsby,
              },
              "&.nav-item-active span:after": {
                width: `100%`,
              },
              "& span:after": {
                position: `absolute`,
                content: `" "`,
                display: `block`,
                width: 0,
                height: 1,
                bottom: -4,
                opacity: 0.2,
                background: `linear-gradient(45deg, ${colors.lilac}, ${
                  colors.gatsby
                })`,
                transition: `all ${transition.speed.default}`,
              },
            },
          }}
          className={isInverted ? `nav-item is-inverted` : `nav-item`}
          activeClassName="nav-item-active"
          onClick={this.handleOnClick}
        >
          <span>{children}</span>
        </Link>
        {sections && sections.length > 0 && (
          <Dropdown bpToggleNavigation={bpToggleNavigation}>
            {sections.map(section => (
              <DropdownItem
                key={`subnav-${section.name}`}
                bpToggleNavigation={bpToggleNavigation}
              >
                <Link to={getLink(section)} className="nav-link-sub">
                  {section.name}
                </Link>
              </DropdownItem>
            ))}
          </Dropdown>
        )}
      </li>
    )
  }
}

NavItem.propTypes = {
  isInverted: PropTypes.bool,
  sections: PropTypes.array,
  linkTo: PropTypes.string.isRequired,
  updateParentState: PropTypes.func.isRequired,
  isLast: PropTypes.bool,
  bpToggleNavigation: PropTypes.string,
}

export default NavItem
