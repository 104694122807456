import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { MdArrowForward } from "react-icons/md"

import { colors, fontSizes, space } from "../utils/presets"
import { getLink } from "../utils/navigation"

const LinkToParentPage = ({ contentfulPage, link, title, isInverted }) => {
  let parentPageLink
  let parentPageTitle

  if (contentfulPage) {
    parentPageLink = getLink(contentfulPage.parentPage)
    parentPageTitle = contentfulPage.parentPage.name
  } else {
    parentPageLink = link
    parentPageTitle = title
  }

  return (
    <Link
      className="gatsby-guide__parent-page-link"
      to={parentPageLink}
      css={{
        fontSize: fontSizes[1],
        color: isInverted ? `rgba(255,255,255,0.65)` : colors.gray.calm,
        position: `relative`,
        display: `inline-flex`,
        alignItems: `center`,
        textDecoration: `none`,
        paddingBottom: space[9],
        "& span:after": {
          background: isInverted ? `rgba(255,255,255,0.45)` : colors.gray.light,
          bottom: 0,
          content: `" "`,
          position: `absolute`,
          left: 0,
          right: 0,
          height: 1,
        },
        "&:hover": {
          color: isInverted ? colors.white : colors.lilac,
          "& span:after": {
            background: isInverted ? colors.white : colors.ui.bright,
          },
        },
      }}
    >
      <span css={{ position: `relative`, marginRight: `.2em` }}>
        {parentPageTitle}
      </span>
      {` `}
      <MdArrowForward />
    </Link>
  )
}

LinkToParentPage.propTypes = {
  contentfulPage: PropTypes.object,
  link: PropTypes.string,
  title: PropTypes.string,
  isInverted: PropTypes.bool,
}

export default LinkToParentPage
