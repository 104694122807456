import React from "react"
import PropTypes from "prop-types"

import { sizes, mediaQueries } from "../../utils/presets"

const heroPaddingTop = {
  paddingTop: `2rem`,
  [mediaQueries.phablet]: {
    paddingTop: `4rem`,
  },
  [mediaQueries.desktop]: {
    paddingTop: `6rem`,
  },
}

const heroInvertedPaddingTop = {
  paddingTop: `calc(2rem + ${sizes.headerHeight})`,
  [mediaQueries.phablet]: {
    paddingTop: `calc(8rem + ${sizes.headerHeight})`,
  },
  [mediaQueries.desktop]: {
    paddingTop: `calc(6rem + ${sizes.headerHeight} + ${
      sizes.headerPaddingTopDesktop
    })`,
  },
}

const HeroContainer = ({
  children,
  isInverted,
  customStyles,
  className = `hero-container`,
}) => (
  <div
    className={className}
    css={{
      position: `relative`,
      ...customStyles,
      ...(isInverted ? heroInvertedPaddingTop : heroPaddingTop),
    }}
  >
    {children}
  </div>
)

HeroContainer.propTypes = {
  isInverted: PropTypes.bool,
  customStyles: PropTypes.object,
  className: PropTypes.string,
}

export default HeroContainer
