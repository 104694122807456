import React from "react"
import PropTypes from "prop-types"

import { subtitleStyles } from "../../utils/styles"
import { colors } from "../../utils/presets"

const Subtitle = ({ children, isInverted, customStyles }) => {
  let fontColor = false

  if (isInverted) {
    fontColor = colors.white
  }

  return (
    <h2
      css={{
        ...subtitleStyles,
        color: fontColor,
        ...customStyles,
      }}
    >
      {children}
    </h2>
  )
}

Subtitle.propTypes = {
  isInverted: PropTypes.bool,
  color: PropTypes.string,
  textAlign: PropTypes.string,
  customStyles: PropTypes.object,
}

export default Subtitle
