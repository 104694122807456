import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { MdArrowForward } from "react-icons/md"
import hex2rgba from "hex2rgba"

import {
  radii,
  transition,
  mediaQueries,
  fonts,
  colors,
  space,
  fontSizes,
} from "../../utils/presets"
import { primaryButtonDefaultStyles } from "../../utils/styles"

const horizontalMargin = space[3]

const defaultStyles = {
  ...primaryButtonDefaultStyles,
  alignItems: `center`,
  // the 1px transparent border hotfixes a Google Chrome rendering
  // quirk, see https://github.com/gatsby-inc/mansion/pull/1448#issue-240448071
  // for a screenshot — the background gradient is not fully drawn,
  // resulting in a black area at the right of the button
  border: `1px solid transparent`,
  borderRadius: radii[2],
  color: colors.white,
  display: `inline-flex`,
  fontFamily: fonts.header,
  fontWeight: `bold`,
  padding: `${space[3]} ${space[4]}`,
  textAlign: `center`,
  textDecoration: `none`,
  transition: `all ${transition.speed.default} ${transition.curve.default}`,
  WebkitFontSmoothing: `antialiased`,
  width: `100%`,
  [mediaQueries.phablet]: {
    fontSize: fontSizes[4],
    width: `auto`,
  },
  ":hover, :focus": {
    background: colors.night,
    boxShadow: `0 0 0 0.2rem ${hex2rgba(colors.lilac, 0.25)}`,
  },
}

const primaryStyle = {
  "&&": {
    ...defaultStyles,
  },
}

const secondaryStyle = {
  "&&": {
    ...defaultStyles,
    color: colors.night,
    fontWeight: `normal`,
    background: colors.white,
    ":hover, :focus": {
      color: colors.gatsby,
      background: colors.white,
      boxShadow: `0 0 0 0.2rem ${hex2rgba(colors.lilac, 0.25)}`,
    },
  },
}

const accentStyle = {
  "&&": {
    ...defaultStyles,
    background: colors.yellow[`500`],
    color: colors.gray.copy,
    fontWeight: `normal`,
    "&&:hover, &&:focus": {
      background: colors.yellow[`600`],
      boxShadow: `0 0 0 0.2rem ${hex2rgba(colors.yellow[`500`], 0.25)}`,
    },
  },
}

export const buttonStyles = {
  primary: primaryStyle,
  secondary: secondaryStyle,
  accent: accentStyle,
}

const Href = ({ to, children, buttonStyle }) => {
  const buttonCSS = buttonStyle
    ? buttonStyles[buttonStyle]
    : buttonStyles.primary

  return (
    <a className="button" css={buttonCSS} href={to}>
      {buttonStyle !== `secondary` && <PrimaryButton>{children}</PrimaryButton>}
      {buttonStyle === `secondary` && <Fragment>{children}</Fragment>}
    </a>
  )
}

Href.propTypes = {
  to: PropTypes.string.isRequired,
  buttonStyle: PropTypes.string,
}

export const PrimaryButton = ({ children }) => (
  <Fragment>
    {children}
    <MdArrowForward css={{ marginLeft: space[2] }} />
  </Fragment>
)

// @todo clean up this mess
export const Button = ({ ariaLabel, to, children, buttonStyle, onClick }) => {
  const isExternal = to && to.indexOf(`http`) === 0
  const buttonCSS = buttonStyle
    ? buttonStyles[buttonStyle]
    : buttonStyles.primary

  return (
    <Fragment>
      {!isExternal && !onClick && (
        <Link className="button" css={buttonCSS} to={to} aria-label={ariaLabel}>
          {buttonStyle !== `secondary` && (
            <PrimaryButton>{children}</PrimaryButton>
          )}
          {buttonStyle === `secondary` && [children]}
        </Link>
      )}
      {!isExternal && onClick && (
        <button
          className="button"
          css={buttonCSS}
          onClick={onClick}
          aria-label={ariaLabel}
        >
          {buttonStyle !== `secondary` && (
            <PrimaryButton>{children}</PrimaryButton>
          )}
          {buttonStyle === `secondary` && [children]}
        </button>
      )}
      {isExternal && (
        <Href to={to} buttonStyle={buttonStyle} aria-label={ariaLabel}>
          {children}
        </Href>
      )}
    </Fragment>
  )
}

Button.propTypes = {
  ariaLabel: PropTypes.string,
  to: PropTypes.string,
  buttonStyle: PropTypes.string,
  onClick: PropTypes.func,
}

export const ButtonGroup = ({ children, customStyles }) => (
  <div
    className="button-group"
    css={{
      marginTop: space[9],
      marginBottom: space[9],
      display: `flex`,
      flexDirection: `column`,
      justifyContent: `center`,
      "&&": {
        "& .button": {
          marginLeft: `0`,
          marginRight: `0`,
          marginBottom: space[6],
        },
        [mediaQueries.tablet]: {
          flexDirection: `row`,
          "& .button": {
            marginRight: `${horizontalMargin}`,
          },
        },
      },
      ...customStyles,
    }}
  >
    {children}
  </div>
)

ButtonGroup.propTypes = {
  customStyles: PropTypes.object,
}
