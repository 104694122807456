import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import config from "../config"
import striptags from "striptags"

const SEO = ({ contentfulPage }) => {
  const description = contentfulPage.title
    ? striptags(contentfulPage.title)
    : config.description

  const title = contentfulPage.name
  let image = config.image

  if (contentfulPage.socialMediaImage) {
    if (contentfulPage.socialMediaImage.file) {
      // explicitly use http to bypass potential SSL certificate issues
      // without this, couldn't get the image to work with https://cards-dev.twitter.com/validator
      // ref. https://toodlepip.co.uk/2017/09/14/how-to-twitter-cards-not-showing-images-on-wordpress/
      // "Apparently, it’s very strict about SSL images, so if the certificate doesn’t exactly match your domain, which it may not on a shared server, the image won’t show. Switching the image so it’s served from a non-SSL server instead, solves the problem"
      image = `http:${contentfulPage.socialMediaImage.file.url}`
    }
  }

  return (
    <Helmet titleTemplate={`%s | ${config.title}`}>
      <title>{title}</title>

      {/* General tags */}
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      {/*
        @todo Schema.org tags
        @see https://github.com/jlengstorf/lengstorf.com/blob/master/src/components/SEO.js
      */}

      {/* OpenGraph tags */}
      <meta property="og:url" content={config.url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="fb:app_id" content={config.fbAppID} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={config.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
}

SEO.propTypes = {
  contentfulPage: PropTypes.object.isRequired,
}

export default SEO

export const socialMediaImageFragment = graphql`
  fragment SocialMediaImage on ContentfulPage {
    socialMediaImage {
      file {
        url
      }
    }
  }
`
