import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { MdArrowForward } from "react-icons/md"

import { fontSizes } from "../utils/presets"

const LinkWithArrow = ({ title }) => {
  const allTitleWords = title.split(/\s+/)
  const lastTitleWord = allTitleWords.pop()
  const firstPartOfTitle = allTitleWords.join(` `)

  return (
    <Fragment>
      {firstPartOfTitle}
      {` `}
      <span css={{ whiteSpace: `nowrap` }}>
        {lastTitleWord}
        {` `}
        <MdArrowForward
          css={{
            fontSize: fontSizes[1],
            marginLeft: 0,
            verticalAlign: `baseline`,
          }}
        />
      </span>
    </Fragment>
  )
}

LinkWithArrow.propTypes = {
  title: PropTypes.string.isRequired,
}

export default LinkWithArrow
