import React from "react"
import { sizes, mediaQueries } from "../../utils/presets"

const pullBelowHeaderStyles = {
  position: `relative`,
  marginTop: `-${sizes.headerHeight}`,
  [mediaQueries.phablet]: {
    marginTop: `calc((${sizes.headerPaddingTopDesktop} + ${
      sizes.headerHeight
    }) * -1)`,
  },
}

const PullBelowHeader = ({ children }) => (
  <div css={{ ...pullBelowHeaderStyles }}>{children}</div>
)

export default PullBelowHeader
